.loading {
    position: relative;
    width: 100px;
    padding-right: 20px;
    margin: 50px auto;
  }
  
  .dot {
    position: absolute;
    top: 0;
    background-color: #A51A1A;
    opacity: 0;
    transform: scale(.7);
    width: 20px;
    height: 20px;
    border-radius: 10px;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
  
  .dot-1 {
    left: 10%;
    animation-delay: 0s;
    animation-name: loading_dot_1;
  }
  
  .dot-2 {
    left: 36.6%;
    animation-delay: 0.15s;
    animation-name: loading_dot_2;
  }
  
  .dot-3 {
    left: 63.6%;
    animation-delay: 0.3s;
    animation-name: loading_dot_3;
  }
  
  .dot-4 {
    left:90%;
    animation-delay: 0.45s;
    animation-name: loading_dot_4;
  }
  
  @keyframes loading_dot_1 {
    0% {
      transform: scale(.7);
      opacity: 0;
      animation-timing-function: ease-out;
    }
    30% {
      transform: scale(1);
      opacity: 1;
      left: 0%;
      animation-timing-function: ease-in;
    }
    80% {
      transform: scale(.7);
      opacity: 0;
    }
    100% {
      transform: scale(.7);
      opacity: 0;
    }
  }
  
  @keyframes loading_dot_2 {
    0% {
      transform: scale(.7);
      opacity: 0;
      animation-timing-function: ease-out;
    }
    30% {
      transform: scale(1);
      opacity: 1;
      left: 33.3%;
      animation-timing-function: ease-in;
    }
    80% {
      transform: scale(.7);
      opacity: 0;
    }
    100% {
      transform: scale(.7);
      opacity: 0;
    }
  }
  
  @keyframes loading_dot_3 {
    0% {
      transform: scale(.7);
      opacity: 0;
      animation-timing-function: ease-out;
    }
    30% {
      transform: scale(1);
      opacity: 1;
      left: 66.6%;
      animation-timing-function: ease-in;
    }
    80% {
      transform: scale(.7);
      opacity: 0;
    }
    100% {
      transform: scale(.7);
      opacity: 0;
    }
  }
  
  @keyframes loading_dot_4 {
    0% {
      transform: scale(.7);
      opacity: 0;
      animation-timing-function: ease-out;
    }
    30% {
      transform: scale(1);
      opacity: 1;
      left: 100%;
      animation-timing-function: ease-in;
    }
    80% {
      transform: scale(.7);
      opacity: 0;
    }
    100% {
      transform: scale(.7);
      opacity: 0;
    }
  }