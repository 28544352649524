.temporal-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -40px;
  margin-bottom: 30px;
}

.temporal-dropdown-div {
  background-color: white;
  padding: 15px 10px;
  border: 1px solid rgb(231, 231, 231);
}

/* 
@media (max-width: 1100px) {
  .temporal-dropdown-div-right {
    border-left: 1px solid rgb(231, 231, 231);
  }
} */

.temporal-dropdown {
  width: 300px;
}

.Dropdown-control {
  border: none !important ;
  /* pssss, don't look at the !important */
}

.temporal-dropwdown-description {
  font-weight: 500;
  margin: 0 0 0 8px;
}
