.log-div {
  position: absolute;
  z-index: 20;
}

.log-form {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 14px;
}

.log-input {
  margin: 7px 0;
  transition: border-color 200ms ease;
}
.log-input:invalid {
  border-color: red;
}
.log-input:focus {
  border-color: rgb(68, 68, 68);
}

.log-title {
  color: rgb(68, 68, 68);
  text-shadow: none;
  text-align: center;
}

.log-button {
  background-color: rgb(134, 35, 35);
  border: 1px solid rgb(134, 35, 35);
  font-size: 18px;
  border-radius: 2px;
  color: white;
  padding: 10px;
  margin: 10px 0;
  transition: background 200ms ease;
  cursor: pointer;
}

.log-email {
  color: rgb(134, 35, 35);
  font-weight: 600;
}

.log-dashboard{
  background-color: rgb(134, 35, 35);
  border: 1px solid rgb(134, 35, 35);
  font-size: 18px;
  border-radius: 2px;
  color: white;
  padding: 10px;
  margin-top: 10px;
  transition: background 200ms ease;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}
