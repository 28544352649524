.card-div {
  width: 25vw;
  min-width: 300px;
  background-size: cover;
  margin: 20px 30px;
  cursor: pointer;
  box-shadow: 0px 0px 48px -2px rgba(185, 185, 185, 0.75);
  transition: transform 100ms ease;
}

.card-image {
  margin-top: -5px;
  width: 100%;
  min-height: 200px;
  height: 20vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-image-type {
  align-self: flex-end;
  margin: 10px;
  background-color: rgb(36, 36, 36);
  color: white;
  padding: 2px 6px 6px 6px;
  border-radius: 4px;
}

.card-link {
  text-decoration: none;
  color: unset;
}

.card-content-div {
  padding: 30px;
}

.card-cont-features {
  margin: 20px 0;
  display: flex;
}

.card-cont-feat-p {
  white-space: nowrap;
  margin: 2px 6px 2px 0;
  font-size: 0.6em;
}

.card-cont-feat-icons-div {
  margin-right: 14px;
}

.card-cont-title {
  font-size: 20px;
  font-weight: 400;
}

.card-cont-ubication {
  font-weight: 500;
  margin-top: 10px;
  color: rgb(158, 158, 158);
  font-size: 12px;
}

.card-image-counter {
  border-radius: 4px;
  margin: 10px;
  background-color: rgba(97, 97, 97, 0.815);
  color: white;
  padding: 2px 6px 2px 6px;
  font-size: 16px;
}

.card-div:hover {
  transform: scale(1.01);
}

.card-image-icon {
  vertical-align: -2px;
  margin-right: 3px;
}

.card-cont-price-title {
  color: rgb(71, 71, 71);
  font-weight: 500;
}

.card-cont-price {
  color: rgb(192, 73, 73);
  font-weight: 700;
  font-size: 1.6em;
}

.card-cont-icons {
  color: rgb(59, 59, 59);

  font-size: 24px;
  vertical-align: -6px;
}

.image-text-span {
  font-size: 15px;
}

.m2card:after {
  margin-top: 5px;
  font-size: 11px;
}

.card-cont-feat-p {
  color: rgb(90, 90, 90);
  font-size: 20px;
}

.card-currency {
  font-size: 17px;
  font-weight: 400;
  color: gray;
}

.card-null {
  width: 100%;
  background-color: rgb(240, 240, 240);
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-null-loading {
  margin: 0;
}

@media (max-width: 768px) {
  .card-div {
    width: 80%;
  }
  .card-cont-features {
    display: none;
  }
  .card-cont-price-title,
  .card-cont-price {
    text-align: end;
  }
}

.hc-main {
  width: 100%;
  margin: 5px;
  border: 1px solid #c4c4c4;
  display: flex;
  flex-direction: row;
}

.hc-image {
  width: 140px;
  /* height: auto; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  margin: 4px 10px 4px 4px;
}

.hc-title{
  font-size: 1.2em;
  font-weight: 400;
}

.hc-tracks{
  padding: 0 20px;
  align-self: center;
}

.hc-track-item > label{
  margin-right: 4px;
}

.hc-body{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
}

.hc-control{
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 0;
}

/* .hc-control-icons{
  
} */

.hc-control-button{
  background: none;
  border: 1px solid rgb(199, 199, 199);
  margin-top: 5px;
  padding: 2px;
  cursor: pointer;
}

.hc-main-paused{
  border: 1px solid rgb(218, 106, 106);
}