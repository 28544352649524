.wps-main{
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: #25D366;
    color: white;
    margin: 20px;
    border-radius: 50%;
    font-size: 2em;
    padding: 5px 9px 3px 11px;
}

.wps-icon{
    color: white;
}