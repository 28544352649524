.footer-div {
  background-color: rgb(27, 27, 27);
  color: rgb(165, 165, 165);
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
}

.footer-subtitle {
  margin: 5px 0 0 5px;
  align-self: center;
}

.footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 40px 0;
}

.footer-top-left {
  display: flex;
  flex-direction: row;
}

.footer-logo {
  min-width: 200px;
  width: 200px;
  height: 50px;
}
.footer-menu-li {
  list-style: none;
  color: rgb(165, 165, 165);
  transition: color 400ms ease;
}

.footer-menu-li:hover {
  color: white;
}

.footer-menu-div {
  display: flex;
}

.footer-menu-li::before {
  content: "▶ ";
  font-size: 10px;
  vertical-align: 2px;
}

.footer-mid {
  display: flex;
  justify-content: space-between;
}

.footer-email {
  text-decoration: none;
  color: rgb(165, 165, 165);
  transition: color 400ms ease;
}

.footer-email:hover {
  color: white;
}

.footer-menu-left {
  margin: 0 20px 0 0;
}

.footer-menu-right {
  margin: 0 -20px 0 0;
}

.footer-bottom {
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-socialmedia {
  margin: 0 10px;
  font-size: 30px;
  transition: color 400ms ease;
  color: rgb(165, 165, 165);
}
.footer-socialmedia:hover {
  color: white;
}

.footer-margin-tb {
  margin: 4px 0;
}

.footer-tel{
  text-decoration: none;
  color: unset;
}

@media (max-width: 768px) {
  .footer-div {
    padding: 10px;
    flex-direction: column;
  }
  .footer-mid {
    flex-direction: column;
    align-items: center;
  }
  .footer-mid-child {
    margin-top: 10px;
  }
  .footer-info-div,
  .footer-types-div {
    margin-top: 20px;
  }
  .footer-subtitle {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1139px) {
  .footer-div {
    padding: 20px;
  }
}
