.contact-background {
  padding: 50px 70px;
  background-color: #f9f9f9;
}

.contact-div {
  padding: 60px 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 20px #e9e9e9;
}
.contact-form-div {
  width: 50%;
  margin-right: 10px;
}
.contact-info-div {
  margin-top: 20px;
  margin-left: 30px;
  width: 50%;
}

.contact-form-form {
  display: flex;
  flex-direction: column;
}

.form-subtitles {
  padding: 5px 0 10px 0;
  margin-bottom: 0;
  color: rgb(53, 53, 53);
}

.contact-field {
  border: 1px solid rgb(184, 184, 184);
  transition: border 200ms ease;
  transition: background-color 200ms ease;
  border-radius: 2px;
  padding: 7px 10px;
  font-size: 14px;
  color: gray;
}

.contact-field:hover {
  background-color: white;

  border: 1px solid rgb(180, 180, 180);
}

.contact-field:focus {
  background-color: white;
  outline: 0;
  border: 1px solid rgb(128, 128, 128);
}

.contact-field-input {
  width: 100%;
  min-height: 25px;
  font-size: 20px;
}
.contact-field-textarea {
  resize: none;
  width: 100%;
}

/* delete arrows in input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.invalid {
  border: 1px #a51a1a solid;
  background-color: rgba(255, 137, 137, 0.068);
}

.contact-info-subtitles {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.form-subtitles {
  font-weight: 400;
  margin-bottom: -5px;
  color: rgb(61, 61, 61);
}

.contact-icons {
  font-size: 20px;
  vertical-align: -8px;
  margin-right: 10px;
  color: rgb(58, 58, 58);
}

.contact-info-text {
  margin-left: 30px;
  color: rgb(68, 68, 68);
}

.contact-form-submit {
  padding: 10px 4px;
  width: 100px;
  border: none;
  color: white;
  background-color: #a51a1a;
}

.contact-form-submit:focus {
  outline: none;
  background-color: #a51a1adc;
}

@media (max-width: 756px) {
  .contact-div {
    padding: 10px;
    flex-direction: column;
    word-wrap: none;
  }
  .contact-background {
    padding: 20px 10px;
  }
  .contact-form-div {
    width: 100%;
  }
}
